/* Single Page */
.single--page{
  display: flex;
  flex-direction: column;
  background-color: #F6F6F6;
  justify-content: space-around;
  padding: 10rem 5rem 5rem 5rem;
}

/* Top Side */

.top--side{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

/* Left Side */
.single--project--left{
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px solid #FFBA2F;
  border-radius: 6px;
  flex-basis: 60%;
  position: relative;
  height: 500px;
}

.single--project--img{
  max-width: 90%;
  max-height: 95%;
  display: block;
  object-fit: contain;
}


/* Right Side */

.single--project--right {
  display: flex;
  flex-basis: 30%;
  text-align: center;
}

.project--title {
  margin-bottom: 3rem;
}

.project--description {
  text-align: left;
  line-height: 1.3;
}
/* Arrows */

.arrow--right {
  scale: 0.8;
  cursor: pointer;
  flex-basis: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
}

.arrow--right--svg:hover path,
.arrow--left--svg:hover path
{
  fill: #FFBA2F;
}

.arrow--left {
  scale: 0.8;
  cursor: pointer;
  flex-basis: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project--info--container a {
  text-decoration: none;
  color: #FFBA2F;
  font-weight: bold;
}

.project--info--container a:hover {
  color: #000;
}
/* Tools */

.tools--container {
  margin-top: 5rem;
}

.tools {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.skill-tag {
  background-color: #FFBA2F;
  border-radius: 50px;
  padding: 3px 9px;
  margin: 5px;
}


/* Bottom Side */

.bottom--side {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: center;
  margin: 1rem 3rem 2rem 6rem;
}

.project--images--one--img {
  width: 200px;
  cursor: pointer;
}

/* Hover */

.project--images--one:hover {
  opacity: 0.8;
}

/* Media Queries */

@media screen and (max-width: 576px) {
  .single--page {
    padding: 8rem 2rem 2rem 2rem;
  }

  .single--page .top--side {
    flex-direction: column;
    align-items: center;
  }

  .single--page .bottom--side {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
    margin: auto;
  }

  .arrow--left,
  .arrow--right {
    display: none;
  }

  .project--title {
    margin-bottom: 0;
  }

  .project--description {
    font-size: 19px;
    text-align: center;
  }

  .project--info--container {
    font-size: 20px;
  }

  .skill-tag {
    font-size: 22px;
    padding: 8px 14px;
    font-weight: bold;
  }

  .single--project--left{
    border: none;
  }

  .project--images--one--img{
    width: 100%;
  }

 }
