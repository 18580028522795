.experience {
  background-color: #ECECEC;
}

.experience--container {
  padding-bottom: 3rem;
}

.experience--header {
  margin: 0;
  padding: 2rem;
  text-align: center;
}

/* Vertical Timeline */

.vertical-timeline-element-icon{
  background-color: #FFBA2F;
  box-shadow: none;
  scale: 0.6;
}

.vertical-timeline-element-icon svg {
  scale: 1.5;
}

.vertical-timeline::before {
  background-color: #FFBA2F;
  width: 2px;
}

.vertical-timeline-element-content-arrow {
  /* border: none; */
  border-right: 7px solid #FFBA2F;
}

.vertical-timeline-element-content {
  background-color: #F6F6F6;
  box-shadow: 0 3px 0 #FFBA2F;
}

.experience--tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
}

.experience--tag {
  background-color: #FFBA2F;
  border-radius: 50px;
  padding: 2px 7px;
  margin: 2px;
  /* color: #fff; */
}

.date.vertical-timeline-element-date{
  font-weight: bold;
}
