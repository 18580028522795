.projects{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../images/lightmode-bg2.svg');
  background-position: top;
  padding-top: 8rem;
}

.project--heading {
  text-align: center;
  padding: 2rem;
}

.projects--filter{
  display: flex;
  justify-content: center;
  text-align: center;
  list-style: none;
  padding: 0;
}

.project--filter--item {
  margin: 1rem;
}

.project--filter--item button{
  background: none;
  border: none;
  cursor: pointer;
  font-family: Poppins !important;
  font-size: 16px;
  font-weight: 500;
}

.project--filter--item button:hover{
  color: #FFBA2F;
  font-weight: bold;
}

/* Project Card */
.projects--gallery--grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin: 4rem 1rem;
}

.projectcard--container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectcard--img--container {
  width: 250px;
  height: 190px;
}

.projectcard--img{
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  object-position: top;
}

.projects--card--link {
  text-decoration: none;
  color: #000;
}

/* Active */

.active{
  color: #FFBA2F;
  font-weight: bold;
}


/* Hover */

.projectcard--img--container:hover{
  opacity: 0.8;
}
