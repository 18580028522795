@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,100&display=swap');


.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato;
}

html {
  scroll-behavior: smooth;
}

#light {
  background-color: #F6F6F6;
}

/* Dark Theme */

#dark {
  background-color: #2A2B32;
}

/* Navbar */

#dark .navbar .logo a{
  color: #fff;
}

/* Dark Theme Introduction */

#dark .intro--content {
  background: url('./images/darkmode-bg.svg');
  background-repeat: repeat;
  background-position: top;
}

#dark .intro--content {
  color: #fff;
}

#dark .intro--content .social--media--intro a{
  color: #fff;
}

#dark .intro--content .social--media--intro a:hover{
  color: #FFBA2F;
}

#dark .social--media--art path {
  fill: #fff;
}

#dark .social--media--art:hover path {
  fill: #FFBA2F;
}

#dark h1::after {
  color: #000;
}

/* Dark Theme Experience */

#dark .experience {
  background-color: #34353A;
  color: #fff;
}

#dark .experience h2 {
  color: #fff;
}

#dark .experience .vertical-timeline-element-content.bounce-in {
  background-color: #2A2B32;
}

/* Dark Theme Skills */

#dark .skills--heading {
  color: #fff;
}

#dark .black--square {
  background-color: #34353A;
}

#dark .experience--tags .experience--tag {
  color: #000000;
}

/* Dark Theme Progress Bar */

#dark .progress--bar--wrapper {
  background-color: #a6a6a6;
}

/* Dark Theme Projects */

#dark .projects {
  background-color: #34353A;
  background-image: url('./images/darkmode-bg2.svg');
  background-repeat: repeat;
}

#dark .projects--heading,
#dark .projects--filter .project--filter--item button {
  color: #fff;
}

/* Dark Theme Hover */

#dark .project--filter--item button:hover{
  color: #FFBA2F;
  font-weight: bold;
}

/* Active project Dark */

#dark .projects--filter .project--filter--item .active{
  color: #FFBA2F;
  font-weight: bold;
}

/* Dark Theme Cards */

#dark .projects--card--link {
  color: #fff;
}

/* Dark Theme Contact */

#dark .contact--heading {
  color: #fff;
}

#dark .contact--form .form--label{
  color: #fff;
}

#dark .contact--form .form--input,
#dark .contact--form .contact--textarea{
  background-color: #46484E;
}

/* Dark Theme Footer */

#dark .footer {
  background-color: #212228;
  color: #fff;
}

#dark .social--media a{
  color: #FFBA2F;
}

#dark .social--media--art--footer path {
  fill: #FFBA2F;
}

/* Dark Theme Footer links Hover */

#dark .footer .social--media a:hover {
  color: #fff;
}

#dark .social--media--art--footer:hover path {
  fill: #fff;
}

/* Dark Theme Single Page */

#dark .single--page {
  background-color: #2A2B32;
  color: #fff;
}

#dark .project--tools .skill-tag{
  color: #000;
}

#dark .arrow--right path,
#dark .arrow--left path {
  fill: #FFBA2F;
}

#dark .arrow--right--svg:hover path,
#dark .arrow--left--svg:hover path
{
  fill: #fff;
}

#dark .project--info--container a {
  color: #FFBA2F;
}

#dark .project--info--container a:hover {
  color: #fff;
}

/* Media Queries */

/* Sizes until 576px */
@media screen and (max-width: 576px) {

  .homepage {
    margin: 5rem 0 0 0;
  }

  /* Navbar */
  /* .navbar .right--side .navbar--link {
    display: none;
  } */

  /* Introduction */

  .intro--content {
    flex-direction: column-reverse;
  }

  .intro--container {
    margin: 3rem 1rem;
  }

  .intro--img--container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .intro--img {
    margin-top: 2rem;
    width: 90%;
  }

  .intro--container .intro--content {
    padding: 0;
  }

  .intro--container .intro--content .intro--info--container {
    width: 100%;
    margin: 3rem 0;
  }

  .break-on-small-screens {
    display: none;
  }

  .intro--info--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }



  .intro--content .intro--info--container .intro--heading{
    /* font-size: 46px; */
    text-align: center;
  }

  .intro--info--container p {
    text-align: center;
    width: 100%;
  }

  .social--media--intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social--media--intro a {
    margin: 0 1.5rem;
  }

  .intro--content .social--media--intro .social--media--art {
    scale: 1.4;
  }

  .intro--content .social--media--intro a {
    scale: 1.7;
  }

  .intro--info--container .contact--me {
    width: 90%;
    height: 4rem;
    margin-top: 2rem;
  }

  /* Skills */

  .skills {
    margin: 0.2rem 1rem;
  }
  .skills .skills--container {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem;
    margin: 0;
  }

  .skills--container .skills--card {
    width: 100%;
  }

  .skills--card .black--square {
    margin-bottom: 1rem;
  }

  .skills--card:last-child {
    margin-bottom: 3rem;
  }

  .black--square--info .custom--skill--header{
    font-size: 24px;
    margin: 1rem;
    color: #FFBA2F;
  }

  .black--square--info .design--icon,
  .black--square--info .js--icon,
  .black--square--info .draw--icon,
  .black--square--info .illustration--icon{
    color: #FFBA2F;
  }

  .black--square--info .js--icon {
    border: 2px solid #FFBA2F;
  }

  .black--square .black--square--info{
    padding: 20px 10px 10px 10px;
  }

  .black--square--info .custom--skill--text{
    font-size: 18px;
    margin: 0;
  }

  /* Projects */

  .homepage .projects {
    padding-top: 4rem;
  }

  .projects .projects--gallery--grid{
    grid-template-columns: repeat(1, 1fr);
  }

  .projects--filter .project--filter--item button {
    font-size: 18;
   }

   .projects--filter .project--filter--item {
      margin: 0.5rem;
   }

   .projectcard--container .projectcard--img--container {
    width: 100%;
    font-size: 20px;
   }

  /* Contact */

  .contact--container {
    margin: 0.2rem 1rem;
  }

  .contact--container .contact--form {
    width: 100%;
  }

  .contact--form label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }

  .contact--form .form--input {
    height: 3rem;
    border-bottom: 3px solid #FFBA2F;
  }

  .contact--form .contact--textarea {
    border-bottom: 3px solid #FFBA2F;
  }

  .contact--form .contact--button {
    width: 100%;
    height: 4rem;
  }

  /* Footer */

  .App .footer .social--media a {
    margin: auto 1.5rem;
  }

}

/* Sizes between 576px to 768px */
@media only screen and (min-width: 576px) and (max-width: 768px) {
  /* Navbar */
  /* .navbar .right--side .navbar--link {
    display: none;
  } */

  /* Introduction */

  .intro--content {
    flex-direction: column-reverse;
  }

  .intro--container {
    margin: 3rem 0;
  }

  .intro--img--container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .intro--img {
    margin-top: 2rem;
    width: 90%;
  }

  .intro--container .intro--content {
    padding: 0;
    margin: 1rem;
  }

  .intro--container .intro--content .intro--info--container {
    width: 100%;
    margin: 0;
  }

  .break-on-small-screens {
    display: none;
  }

  .intro--info--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro--info--container .intro--heading{
    font-size: 46px;
  }

  .intro--info--container p {
    text-align: center;
    width: 85%;
  }

  .social--media--intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social--media--intro a {
    margin: 0 2rem;
  }

  .intro--content .social--media--intro .social--media--art {
    scale: 1.4;
  }

  .intro--content .social--media--intro a {
    scale: 1.7;
  }

  .intro--info--container .contact--me {
    width: 90%;
    height: 4rem;
    margin-top: 2rem;
  }

  /* Skills */

  .skills .skills--container {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    margin: 0;
  }

  .skills--card .black--square {
    margin-bottom: 1rem;
  }

  .skills--card:last-child {
    margin-bottom: 3rem;
  }

  /* Projects */

  .homepage .projects {
    padding-top: 4rem;
  }

  .projects .projects--gallery--grid{
    grid-template-columns: repeat(2, 1fr);
  }

  .projects--filter .project--filter--item button {
    font-size: 18;
   }

  /* Contact */

  .contact--container .contact--form {
    width: 80%;
  }

  .contact--form .contact--button {
    width: 100%;
    height: 4rem;
  }

  /* Footer */

  .footer {
    padding: 0;
  }

  .footer .social--media a {
    margin: auto 1rem;
  }
}

/* Sizes between 768px to 992px */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  /* Navbar */
  /* .navbar .right--side .navbar--link {
    display: none;
  } */

  /* Introduction */

  .intro--content {
    flex-direction: column-reverse;
  }

  .intro--container {
    margin: 3rem 0;
  }

  .intro--img--container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .intro--img {
    margin-top: 4rem;
    width: 90%;
  }

  .intro--container .intro--content {
    padding: 0;
    margin: 1rem;
  }

  .intro--container .intro--content .intro--info--container {
    width: 100%;
    margin: 3rem 0;
  }

  .break-on-small-screens {
    display: none;
  }

  .intro--info--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro--content .intro--info--container .intro--heading{
    font-size: 46px;
  }

  .intro--info--container p {
    text-align: center;
    width: 65%;
  }

  .social--media--intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social--media--intro a {
    margin: 0 2rem;
  }

  .intro--content .social--media--intro .social--media--art {
    scale: 1.4;
  }

  .intro--content .social--media--intro a {
    scale: 1.7;
  }

  .intro--info--container .contact--me {
    width: 65%;
    height: 4rem;
    margin-top: 2rem;
  }

  /* Skills */

  .skills .skills--container {
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    margin: 0;
  }

  .skills--card .black--square {
    margin-bottom: 1rem;
  }

  .skills--card:last-child {
    margin-bottom: 3rem;
  }

  /* Projects */

  .homepage .projects {
    padding-top: 4rem;
  }

  .projects .projects--gallery--grid{
    grid-template-columns: repeat(2, 1fr);
  }

  .projects--filter .project--filter--item button {
    font-size: 18;
   }

  /* Contact */

  .contact--container .contact--form {
    width: 80%;
  }

  .contact--form .contact--button {
    width: 65%;
    height: 4rem;
  }

  /* Footer */

  .footer .social--media a {
    margin: auto 1rem;
  }
}

/* Sizes between 992px to 1200px */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* Navbar */
  /* .navbar .right--side .navbar--link {
    display: none;
  } */

  /* Introduction */

  .intro--content {
    flex-direction: column-reverse;
  }

  .intro--container {
    margin: 3rem 0;
  }

  .intro--img--container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .intro--img {
    margin-top: 4rem;
    width: 60%;
  }

  .intro--container .intro--content {
    padding: 0;
    margin: 1rem;
  }

  .intro--container .intro--content .intro--info--container {
    width: 100%;
    margin: 3rem 0;
  }

  .break-on-small-screens {
    display: none;
  }

  .intro--info--container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro--info--container .intro--heading{
    font-size: 46px;
  }

  .intro--info--container p {
    text-align: center;
    width: 60%;
  }

  .social--media--intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .social--media--intro a {
    margin: 0 2rem;
  }

  .intro--content .social--media--intro .social--media--art {
    scale: 1.4;
  }

  .intro--content .social--media--intro a {
    scale: 1.7;
  }

  .intro--info--container .contact--me {
    width: 60%;
    height: 4rem;
    margin-top: 2rem;
  }

  /* Skills */

  .skills .skills--container {
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    margin: 0 0 5rem 0;
  }

  .skills--card .black--square {
    margin-bottom: 1rem;
  }
  /* Projects */

  .homepage .projects {
    padding-top: 4rem;
  }

  .projects .projects--gallery--grid{
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .projects--filter .project--filter--item button {
    font-size: 18;
   }

  /* Contact */

  .contact--container .contact--form {
    width: 80%;
  }

  .contact--form .contact--button {
    width: 60%;
    height: 4rem;
  }

  /* Footer */

  .footer .social--media a {
    margin: auto 1rem;
  }
}
