/* Container */
.intro--content {
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #F6F6F6;
  background: url('../images/lightmode-bg.svg');
  background-repeat: no-repeat;
  background-position: center top;
}

/* Info Container */

.intro--content .intro--info--container {
  width: 230px;
  margin-right: 7rem;
}


/* Heading */

.intro--heading {
  font-size: 42px;
  margin: 0;
}

.yellow {
  color: #FFBA2F;
  cursor: pointer;
}

h1 {
  position: relative;
  display: inline-block;
}

h1::after {
  content: "Pls hire me!";
  position: absolute;
  font-size: 16px;
  text-align: center;
  top: 45%;
  left: 60%;
  transform: translateY(-200%);
  width: 100px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px 10px 10px 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

h1:hover::after {
  opacity: 1;
  visibility: visible;
}

/* Social Media */

.social--media--intro {
  display: flex;
  justify-content: space-between;
  margin: 2rem auto;
}

.intro--content .social--media--intro a{
  color: #000;
}

.intro--content .social--media--intro a:hover{
  color: #FFBA2F;
}

/* Contact me button */

.contact--me {
  width: 100%;
  border: none;
  background-color: #FFBA2F;
  color: #fff;
  height: 44px;
  border-radius: 6px;
  font-size: 22px;
  cursor: pointer;
}

.contact--me:hover{
  border: 2px solid #FFBA2F;
  background-color: rgba(255, 255, 255, 0.1);
}

.social--media--art{
  scale: 0.8;
}

.social--media--art:hover path {
  fill: #FFBA2F;
}

.contact--me--link {
  text-decoration: none;
  color: #fff;
}

.contact--me--link:hover {
  color: #FFBA2F;
}
