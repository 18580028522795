.skills--heading {
  text-align: center;
  padding: 2rem;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills--container{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5rem;
  margin: 3rem 10rem;
  align-items: start;
}


.skills--card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 220px;
}

/* Black Square */
.black--square {
  height: 250px;
  background-color: #2A2B32;
  border-radius: 12px;
  margin-bottom: 3rem;
}

.black--square--info {
  padding: 50px 10px 10px 10px;
}

.js--icon {
  scale: 2;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 4px;
}

.draw--icon {
  scale:2;
  color: #fff;
}

.illustration--icon {
  scale:2;
  color: #fff;
}

.design--icon{
  scale:2;
  color: #fff;
}

.graphic--design--icon {
  scale: 0.5;
}

.custom--skill--header{
  color: #fff;
}

.custom--skill--text {
  color: #fff;
  font-size: 12px;
  padding: 0 20px;
}

/* Progress Bar */

.progress--bar--wrapper {
  height: 25px;
  background-color: #e0e0de;
  margin-top: 14px;
  border-radius: 6px;
}

.progress--bar--container {
  height: 100%;
  border-radius: inherit;
  text-align: right;
  animation: fillAnimation 2s ease-in-out;
}

@keyframes fillAnimation {
  0% {
    width: 0;
  }
  100% {
    width: var(--completed, 0%);
  }
}

.progress--bar--skill,
.progress--bar--completed{
  padding: 8px;
  color: #000;
  font-weight: bold;
}
