.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #EFEFEF;
}

.footer .social--media {
  margin: 1rem 0;
}

.footer .social--media a{
  color: #000;
  margin: auto 2rem;
}

.footer .social--media a:hover {
  color: #FFBA2F;
}

.social--media--art--footer path{
  scale: 0.8;
}

.social--media--art--footer:hover path {
  fill: #FFBA2F;
}
