/* NavBar */
.navbar {
  width: 100%;
  display: flex;
  background-color: #F6F6F6;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s, opacity 0.3s;
}

.navbar.scrolled {
  background-color: rgba(216, 216, 216, 0.8);
  opacity: 0.9;
  border-bottom: 2px solid #F0AD2C;
}

#dark.navbar.scrolled {
  background-color: rgba(51, 51, 51, 0.8);
  opacity: 0.9;
  border-bottom: 2px solid #F0AD2C;
}

.navbar .left--side {
  display: flex;
  align-items: center;
  margin-left: 4rem;
}

.navbar .left--side a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.navbar .logo {
  font-size: 24px;
  margin-left: 2rem;
}



.navbar .left--side .logo--yellow{
  color: #F0AD2C;
}

.navbar .right--side {
  display: flex;
  align-items: center;
  margin-right: 4rem;
}

/* NavBar Links */

.navbar .right--side a {
  margin-right: 3rem;
  text-decoration: none;
  color: #000;
  font-weight: 700;
}

.navbar .right--side a:hover {
  color: #F0AD2C;
}

.navbar .right--side .navbar--link{
  position: relative;
  text-decoration: none;
  color: #000;
}

.navbar .right--side .navbar--link:hover {
  transition: 0.3s;
  color: #F0AD2C;
}

.navbar .right--side .navbar--link:after {
  content: '';
  position: absolute;
  background-color: #F0AD2C;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -13px;
  transition: 0.3s;
}

.navbar .navbar--link:hover::after {
  width: 100%;
}

/* Active */

.navbar .navbar--link.active {
  color: #F0AD2C;
}

.navbar .navbar--link.active::after {
  color: #F0AD2C;
  content: '';
  position: absolute;
  background-color: #F0AD2C;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
  font-weight: bold;
}

/* Dark Theme */

#dark .navbar {
  background-color: #2A2B32;
}

#dark .navbar--link{
  color: #fff;
}

/* Dark theme hovers */

#dark .navbar .right--side a:hover {
  color: #F0AD2C;
}

#dark .navbar .right--side .navbar--link{
  position: relative;
  text-decoration: none;
}

#dark .navbar .right--side .navbar--link:hover {
  transition: 0.3s;
  color: #F0AD2C;
}

#dark .navbar .right--side .navbar--link:after {
  content: '';
  position: absolute;
  background-color: #F0AD2C;
  height: 2px;
  width: 0;
  left: 0;
  bottom: -13px;
  transition: 0.3s;
}

#dark .navbar .navbar--link:hover::after {
  width: 100%;
}

/* Active */

#dark .navbar .navbar--link.active {
  color: #F0AD2C;
}

#dark .navbar .navbar--link.active::after {
  color: #F0AD2C;
  content: '';
  position: absolute;
  background-color: #F0AD2C;
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
  font-weight: bold;
}

/* Hamburger Menu Mobile */

.nav--btn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  visibility: hidden;
  opacity: 0;
}

@media only screen and (max-width: 992px) {

  .navbar .left--side{
    margin-left: 2rem;
  }

  .navbar .logo {
    font-size: 20px;
  }

  .nav--btn {
    visibility: visible;
    opacity: 1;
    font-size: 1.8rem;
  }

  .nav--menu--btn {
    margin-right: 2rem;
  }

  nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #818181;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100vh);
    transition: 1s;
    font-size: 26px;
  }

  nav .navbar--switch{
    display: none;
  }

  nav a {
    margin: 2rem;
  }

  .responsive--nav {
    transform: none;
  }


  .nav--close--btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

}

/* Scroll to top */

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  color: #979797;
  border: 3px solid #979797;
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: none;
}

.scroll-to-top:hover {
  color: #F0AD2C;
  border: 3px solid #F0AD2C;
}

/* Dark Theme */

#dark .scroll-to-top {
  color: #fff;
  border: 3px solid #fff;
}

#dark .scroll-to-top:hover {
  color: #F0AD2C;
  border: 3px solid #F0AD2C;
}
