.contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact--heading {
  text-align: center;
  padding: 2rem;
  color: #2A2B32;
}

.contact--form{
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
}

.form--label {
  color: #34353A;
  font-weight: 300;
  margin-bottom: 0.5rem;
}

.form--input {
  background-color: #D9D9D9;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid #FFC221;
  height: 2rem;
  margin-bottom: 1rem;
}

.form--input:focus {
  outline: none;
  background-color: #d5d5d5;
}

.contact--textarea {
  background-color: #D9D9D9;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid #FFC221;
  margin-bottom: 1rem;
  resize: none;
}

.contact--textarea:focus{
  outline: none;
  background-color: #d5d5d5;
}

.contact--button {
  width: 40%;
  border: none;
  background-color: #FFBA2F;
  color: #fff;
  height: 44px;
  border-radius: 6px;
  font-size: 22px;
  cursor: pointer;
  margin: 2rem auto;
}

.contact--button:hover{
  border: 2px solid #FFBA2F;
  color: #FFBA2F;
  background-color: rgba(255, 255, 255, 0.1);
}
